import React from 'react';
import './App.css';
import {Box, Card, CardContent, CssBaseline, Grid, Stack, Typography} from "@mui/material";
import {Helmet, HelmetProvider} from "react-helmet-async";

function App() {

    const title = 'Antoine OBLIGIS'
    const primaryColor = '#006CB0'
    const lang = 'fr'
    return (
        <>
            <CssBaseline/>
            <HelmetProvider>
                <Helmet htmlAttributes={{lang}}>
                    <title>{title}</title>
                </Helmet>
                <Box>
                    <Grid container spacing={2} sx={{width: '100%', height: '100vh', margin: 0, bgcolor: primaryColor}}>
                        <Grid item xs={12} md={6} pr="16px" pb="16px" bgcolor={primaryColor}>
                            <Stack justifyContent="center" alignItems="center" spacing={5}>
                                <img src="/assets/logo.png" id="logo" alt="logo"/>
                                <Typography align="center" variant="h1" color="white">Antoine OBLIGIS</Typography>
                                <Card sx={{width: '90%'}}>
                                    <CardContent>
                                        <Stack justifyContent="center" alignItems="center" spacing={2}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} md={6}>
                                                    <Typography variant='h4'>Téléphone : </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <Typography variant='h4'>06.65.10.41.88</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} md={6}>
                                                    <Typography variant='h4'>Email : </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <Typography variant='h4'>antoine@obligis.fr</Typography>
                                                </Grid>
                                            </Grid>

                                        </Stack>
                                    </CardContent>
                                </Card>
                                <Grid container sx={{width: '100%', margin: "none"}} spacing={2}>
                                    <Grid item xs={12} md={4}>
                                        <Card>
                                            <CardContent sx={{textAlign: "center", pb: "16px !important"}}>
                                                <Typography variant='h4'>
                                                    Mission
                                                </Typography>
                                                <></>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Card>
                                            <CardContent sx={{textAlign: "center", pb: "16px !important"}}>
                                                <Typography variant='h4'>
                                                    site vitrine
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Card>
                                            <CardContent sx={{textAlign: "center", pb: "16px !important"}}>
                                                <Typography variant='h4'>
                                                    Formation
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6} bgcolor="black">
                            <Stack justifyContent="center" alignItems="center" spacing={2} height="100%">
                                <Card sx={{width: '90%', bgcolor: primaryColor, color: "white"}}>
                                    <CardContent>
                                        <Typography gutterBottom variant='h4'>
                                            Compétences
                                        </Typography>
                                        <Grid container sx={{width: '100%'}} ml="-8px" spacing={2}>
                                            <Grid item xs={6} md={3}>
                                                <Card>
                                                    <CardContent sx={{textAlign: "center", pb: "16px !important"}}>
                                                        <Typography variant='h5'>
                                                            PHP
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                            <Grid item xs={6} md={3}>
                                                <Card>
                                                    <CardContent sx={{textAlign: "center", pb: "16px !important"}}>
                                                        <Typography variant='h5'>
                                                            Symfony
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                            <Grid item xs={6} md={3}>
                                                <Card>
                                                    <CardContent sx={{textAlign: "center", pb: "16px !important"}}>
                                                        <Typography variant='h5'>
                                                            API platform
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                            <Grid item xs={6} md={3}>
                                                <Card>
                                                    <CardContent sx={{textAlign: "center", pb: "16px !important"}}>
                                                        <Typography variant='h5'>
                                                            React
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                            <Grid item xs={6} md={3}>
                                                <Card>
                                                    <CardContent sx={{textAlign: "center", pb: "16px !important"}}>
                                                        <Typography variant='h5'>
                                                            PhpUnit
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                            <Grid item xs={6} md={3}>
                                                <Card>
                                                    <CardContent sx={{textAlign: "center", pb: "16px !important"}}>
                                                        <Typography variant='h5'>
                                                            Jira
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                            <Grid item xs={6} md={3}>
                                                <Card>
                                                    <CardContent sx={{textAlign: "center", pb: "16px !important"}}>
                                                        <Typography variant='h5'>
                                                            Git
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                            <Grid item xs={6} md={3}>
                                                <Card>
                                                    <CardContent sx={{textAlign: "center", pb: "16px !important"}}>
                                                        <Typography variant='h5'>
                                                            Agile
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        </Grid>

                                    </CardContent>
                                </Card>
                                <Card sx={{width: '90%', bgcolor: primaryColor, color: "white"}}>
                                    <CardContent>
                                        <Typography gutterBottom variant='h4'>Expériences</Typography>
                                        <Grid container sx={{width: '100%'}} ml="-8px" spacing={2}>
                                            <Grid item xs={12} md={4}>
                                                <Card>
                                                    <CardContent>
                                                        <Typography variant='h6'>
                                                            CEFIM
                                                        </Typography>
                                                        <Typography variant='body1'>
                                                            Formateur
                                                        </Typography>
                                                        <Typography variant='body2'>
                                                            freelance
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <Card>
                                                    <CardContent>
                                                        <Typography variant='h6'>
                                                            Monkey-tie
                                                        </Typography>
                                                        <Typography variant='body1'>
                                                            Lead developer
                                                        </Typography>
                                                        <Typography variant='body2'>
                                                            Salarié et freelance
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <Card>
                                                    <CardContent>
                                                        <Typography variant='h6'>
                                                            You-trust
                                                        </Typography>
                                                        <Typography variant='body1'>
                                                            Lead developer
                                                        </Typography>
                                                        <Typography variant='body2'>
                                                            Salarié
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <Card>
                                                    <CardContent>
                                                        <Typography variant='h6'>
                                                            Solution alerte-vigipirate
                                                        </Typography>
                                                        <Typography variant='body1'>
                                                            CTO
                                                        </Typography>
                                                        <Typography variant='body2'>
                                                            Freelance
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <Card>
                                                    <CardContent>
                                                        <Typography variant='h6'>
                                                            Clearnox
                                                        </Typography>
                                                        <Typography variant='body1'>
                                                            Developer
                                                        </Typography>
                                                        <Typography variant='body2'>
                                                            Salarié
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                                <Card sx={{width: '90%', bgcolor: primaryColor, color: "white"}}>
                                    <CardContent>
                                        <Typography gutterBottom variant='h4'>Formation</Typography>
                                        <Grid container sx={{width: '100%'}} ml="-8px" spacing={2}>
                                            <Grid item xs={12} md={6}>
                                                <Card>
                                                    <CardContent>
                                                        <Typography variant='h6'>
                                                            Concepteur/Développeur Informatique
                                                        </Typography>
                                                        <Typography variant='body1'>
                                                            CEFIM
                                                        </Typography>
                                                        <Typography variant='body2'>
                                                            Equivalent bac+4 - 2016-2017
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Card>
                                                    <CardContent>
                                                        <Typography variant='h6'>
                                                            Développeur Logiciel
                                                        </Typography>
                                                        <Typography variant='body1'>
                                                            IMIE
                                                        </Typography>
                                                        <Typography variant='body2'>
                                                            Equivalent bac+2 - 2015- 2016
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Stack>
                        </Grid>
                    </Grid>

                </Box>
            </HelmetProvider>
        </>
    )
        ;
}

export default App;
